<template>
  <a
    :href="adLink"
    class="ad d-block px-3 py-4 text-center"
    :class="linkClassObj"
  >
    <h1 class="text-green font-weight-bold text-left" :class="headerClassObj">
      FREE online 360º Health consultation...
    </h1>

    <p class="h4 text-left mt-4" :class="bodyClassObj">
      Get your complimentary online consultation with one of our 360º Health
      practitioners.
    </p>

    <b-btn
      class="mt-3 text-white rounded-0 py-3 text-truncate am-btn-blue"
      block="true"
    >
      Click to find out more
    </b-btn>
  </a>
</template>

<script>
import sideBarAds from "../../../sideBarAds";

export default {
  mixins: [sideBarAds],
};
</script>

<style lang="scss" scoped>
.ad {
  background-image: url("/images/side-bar-ads/360-background_blue.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.ad.light {
  background-image: url("/images/side-bar-ads/360-background-light.jpg") !important;
}
</style>