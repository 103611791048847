<template>
  <b-col class="px-0" id="online-consultation">
    <b-row>
      <b-col>
        <component :is="loadedAd" :theme="variation" :adId="adId"></component>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import ConsultationComponent from "./ConsultationComponent.vue";
import ThreeSixtyHealth from "./sidebarads/360Health.vue";
import GutHealth from "./sidebarads/GutHealth.vue";
import HormoneHealth from "./sidebarads/HormoneHealth.vue";
import GeneticHealth from "./sidebarads/GeneticHealth.vue";
import FreeHealthConsultation from "./sidebarads/FreeHealthConsultation.vue";

export default {
  props: ["ad_id"],
  data() {
    return {
      loadedAd: "ThreeSixtyHealth",
      variation: "dark",
    };
  },

  computed: {
    loadedUrl() {
      return `${this.loadedAd.url}?ad=${this.loadedAd.id}`;
    },
  },

  created() {
    // let adId = window != undefined ? window.sideBarAd : 2;
    // this.adId = adId;

    let adId = this.ad_id ? this.ad_id : 2;
    this.adId = adId;

    switch (this.adId) {
      case 1:
        this.loadedAd = "ThreeSixtyHealth";
        this.variation = "light";
        break;
      case 2:
        this.loadedAd = "ThreeSixtyHealth";
        this.variation = "dark";
        break;
      case 3:
        this.loadedAd = "GutHealth";
        this.variation = "light";
        break;
      case 4:
        this.loadedAd = "GutHealth";
        this.variation = "dark";
        break;
      case 5:
        this.loadedAd = "HormoneHealth";
        this.variation = "light";
        break;
      case 6:
        this.loadedAd = "HormoneHealth";
        this.variation = "dark";
        break;
      case 7:
        this.loadedAd = "GeneticHealth";
        this.variation = "light";
        break;
      case 8:
        this.loadedAd = "GeneticHealth";
        this.variation = "dark";
        break;
      case 9:
        this.loadedAd = "FreeHealthConsultation";
        this.variation = "light";
        break;
      case 10:
        this.loadedAd = "FreeHealthConsultation";
        this.variation = "dark";
        break;
    }
  },

  components: {
    ConsultationComponent,
    ThreeSixtyHealth,
    GutHealth,
    HormoneHealth,
    GeneticHealth,
    FreeHealthConsultation,
  },
};
</script>