<template>
  <div>
    <div class="row my-3">
      <div class="col-12 px-0" id="online-consultation">
        <side-bar-ad :ad_id="ad_id"></side-bar-ad>
      </div>
      <div class="col-12 px-0 mt-2">
        <div
          class="card mb-3"
          style="border-radius: 0"
          v-if="relatedContent.length > 0"
        >
          <div class="card-header border-0 bg-custom-blue text-white">
            <span class="h5 font-weight-bold">Related Content</span>
          </div>
          <div class="card-body p-1">
            <ul class="list-group list-unstyled">
              <li
                class="list-group-item mb-0 pb-0"
                v-for="related in relatedContent"
                :key="related.id"
              >
                <router-link
                  :to="'/' + related.category.slug + '/' + related.slug"
                  >{{ related.title }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="card mb-3" style="border-radius: 0">
          <div class="card-header border-0 bg-custom-blue text-white">
            <span class="h5 font-weight-bold">Other Categories</span>
          </div>
          <div class="card-body p-1">
            <ul class="list-group list-unstyled">
              <li
                class="list-group-item mb-0 pb-0"
                v-for="category in categories"
                :key="category.id"
              >
                <router-link :to="'/' + category.slug">{{
                  category.title
                }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBarAd from "./ads/SideBarAd";

export default {
  props: ["relatedContent", "categories", "ad_id"],
  components: {
    SideBarAd,
  },
};
</script>

<style>
</style>