<template>
	<div class="row mb-3">
		<div class="col">
			<div class="p-3 online-consultation-container">
				<h1
					:class="{'small-header': smallFont}"
					class="text-success"
				>
					<span class='font-weight-bold'>Free</span> Online Consultation
				</h1>
				<p 
					:class="{'small-description': smallFont}"
					class="text-white"
				>
					Get your <span class='font-weight-bold'> Free 15 minute online consultation</span> with an Amchara hormones expert
				</p>
				<div class="form-group">
					<input type="text" placeholder="Full Name*" v-model="name" v-bind:disabled="success">
				</div>
				<div class="form-group">
					<input type="email" placeholder="Email*" v-model="email" v-bind:disabled="success">
				</div>

				<div class="form-group">
					<input type="number" placeholder="Phone*" v-model="phone" v-bind:disabled="success">
				</div>

				<div class="form-group text-center mt-4 hideConsultFormBtn">
					<button class="btn btn-white btn-lg" @click.prevent="sendData">Submit</button>
				</div>
				<div class="row">
					<div class="col">
						<p v-show="success" class="text-white">Thanks! You will hear from us shortly..</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: ["mainHeader", "description"],
		data () {
			return {
				email: "",
				name: "",
				phone: "",
				success: false,
				smallFont: false,
				path: ""
			}
		},

		methods: {
			sendData () {
				if (!this.success) {
					axios.post('/enquiries/request-consultation', {
						email: this.email,
						phone: this.phone,
						name: this.name,
						path: this.path
					})
					.then(response => {
						if (response.data.success) {
							this.success = true;
							this.email = "";
							this.phone = "";
							this.name = "";
						}
						$('.hideConsultFormBtn').hide()
					})
					.catch(response => {
						console.error("There was a problem...")
					});
				}
			}
		},

		mounted () {
			this.path = window.location.pathname;
		}
	}
</script>
<style lang="scss" scoped>
div.online-consultation-container {
	background-image: url('/img/green-computer.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;

	input {
		width: 100%;
		border: none;
		height: 45px;
		padding-left: 5px;
	}
}
</style>